import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateMask',
})
export class DateMaskPipe implements PipeTransform {
  transform(value: any, char: string = '*'): string {
    if (!value) return value;

    const date = new Date(value);
    const year = date.getFullYear();

    return `${char}${char}/${char}${char}/${year}`;
  }
}
