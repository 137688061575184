export const ROUTER = {
    VERIFY_SIM: 'xac-nhan-sim',
    VERIFY_INFO: 'xac-nhan-thong-tin',
    OCR_EKYC: 'anh-giay-to',
    VIDEO_CALL: 'goi-dien-xac-thuc',
    CONFIRM_INFO: 'xac-thuc',
    SIGN_CONTRACT: 'ky-hop-dong',

    PHONE_NUMBER_LOOKUP: 'tra-cuu-thue-bao',
    PHONE_NUMBER_DETAIL: 'thong-tin-thue-bao',
    CAPTURE_IMAGE_OCR_EKYC: 'chup-anh-giay-to',

    PAGE_NOT_FOUND: '404-not-found'
}