export const environment = {
  production: false,
  apiUrl: 'https://saymee.osp.vn/api',
  u: 'webview_azota',
  p: 'QaAz$z7SKymDEkDh',
  baseHref: '/',
  firebaseConfig: {
    apiKey: "AIzaSyAyPkt7MguI15hhF6slA7AS4EhFCjNIjaM",
    authDomain: "saymee-1108e.firebaseapp.com",
    databaseURL: "https://saymee-1108e-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "saymee-1108e",
    storageBucket: "saymee-1108e.appspot.com",
    messagingSenderId: "816410536379",
    appId: "1:816410536379:web:8c40f49a142acca5c8bc9e",
    measurementId: "G-DSJFCL4JV0"
  }
};
