<!-- <app-header></app-header> -->
<div [ngClass]="isUpdateInfoRouter() ? 'bg-[#F9F9F9] px-0' : 'px-4'">
  <div *ngIf="!isUpdateInfoRouter()" class="my-6">
    <h3 class="text-[28px] text-black text-center font-bold mb-2">Kích hoạt SIM</h3>
    <saymee-step [currentStep]="currStep"></saymee-step>
  </div>
  <div class="container mx-auto">
    <router-outlet></router-outlet>
  </div>
</div>
