import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/core/services/destroy.service';
import { NotificationModel, NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss']
})
export class ToastrComponent implements OnInit {
  notification: NotificationModel = {
    type: 'success',
    title: null,
    message: null
  };

  isShowNoti = false;

  constructor(
    private notificationService: NotificationService,
    private readonly destroy$: DestroyService
  ) { }
  
  ngOnInit(): void {
    this.notificationService.getNotificationSubject()
      .pipe(takeUntil(this.destroy$))
      .subscribe(notification => {
        this.notification = notification;
        this.isShowNoti = true;
        
        setTimeout(() => {
          this.isShowNoti = false;
        }, this.notification.duration ?? 3000);
      });
  }
}
