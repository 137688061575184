import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, switchMap, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageUtil } from '../utils';
import {AuthService} from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any> = req.clone();
    const token = localStorage.getItem('accessToken');
    newReq = this.addTokenHeader(req, token);
    if (!window.navigator.onLine) {
      // if there is no internet, throw a HttpErrorResponse error
      // since an error is thrown, the function will terminate here
      // this.notification.open({
      //   type: 'error',
      //   content: 'Hệ thống có lỗi, bạn vui lòng thực hiện lại sau'
      // });
      return throwError(
        new HttpErrorResponse({
          error: 'Hệ thống có lỗi, bạn vui lòng thực hiện lại sau'
        })
      );
    }
    return next.handle(newReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401
        ) {
        	return this.handle401Error(newReq, next);
        }

        if ([0, 500].includes(error.status) || !navigator.onLine) {
          // this.notification.open({
          //   type: 'error',
          //   content: 'Hệ thống có lỗi, bạn vui lòng thực hiện lại sau'
          // });
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    localStorage.removeItem('accessToken');
    return this.authService.login().pipe(
      switchMap((token: any) => {
        LocalStorageUtil.setItem('accessToken', token.data.accessTokenInfo.accessToken);
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token.data.accessTokenInfo.accessToken}`
          }
        });
        return next.handle(request);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: any): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${token}`
      )
    });
  }
}
