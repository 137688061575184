<div class="py-3 flex flex-row justify-center items-center">
  <img
    *ngIf="!isUpdateInfoRouter() else title"
    src="./assets/images/logo-saymee.webp"
    alt="saymee logo"
    class="w-14 h-14"
  />
  <ng-template #title>
    <button class="left-5 absolute" (click)="backToPreviousPage()">
      <img src="./assets/icons/icon-arrow-left.svg" alt="" />
    </button>
    <p class="text-base font-semibold justify-self-center">Bổ sung giấy tờ</p>
  </ng-template>
</div>
