import { NgModule } from "@angular/core";
import { ComponentsModule } from "./components/components.module";
import { ControlsModule } from "./controls/controls.module";
import { DirectivesModule } from "./directives/directives.module";
import { PipesModule } from "./pipes/pipes.module";
import { ZorroAntdModule } from "./zorro-antd.module";

@NgModule({
  exports: [
    ComponentsModule,
    ControlsModule,
    DirectivesModule,
    PipesModule,
    ZorroAntdModule
  ]
})

export class SharedModule {}
