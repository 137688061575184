import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgIterable,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface IConfig {
  inputStyles?: {
    [key: string]: any;
  };
  length: NgIterable<any> | null | undefined;
  inputClass?: string;
}

@Component({
  selector: 'input-serial-number',
  templateUrl: './input-serial-number.component.html',
  styleUrls: ['./input-serial-number.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSerialNumberComponent),
      multi: true,
    },
  ],
})
export class InputSerialNumberComponent implements ControlValueAccessor, OnChanges {
  @ViewChild('listInputs') serialInputs!: ElementRef;

  @Input() isHasError: boolean = false;

  @Input('length')
  set length(value: number) {
    this.config.length = new Array(value);
    this.serialLength = value;
  }

  @Input() isFirstInputFocus = false;

  @Output() onfocus = new EventEmitter();

  config: IConfig = {
    length: [],
    inputClass: 'serial-input',
    inputStyles: {
      borderColor: '#EBEBEB',
      borderRadius: '8px',
      borderWidth: '2px',
      textAlign: 'center',
      fontSize: '26px',
      outline: 'none',
      width: '40px',
      height: '40px',
    },
  };

  serial: any[] = [];
  serialLength: number = 0;

  private _onChange!: (_: any) => void;
  private _onTouched!: (_?: any) => void;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('isHasError' in changes) {
      if (this.config.inputStyles && this.config.inputStyles['borderColor'] && changes.isHasError.currentValue) {
        this.config.inputStyles['borderColor'] = '#FF005A';
      }
      if (this.config.inputStyles && this.config.inputStyles['borderColor'] && !changes.isHasError.currentValue && this.serial.some((item) => !!item)) {
        this.config.inputStyles['borderColor'] = '#75E5DC';
      }
      if (changes.isHasError.currentValue) {
        this.config.inputClass += ' input-error';
      }
    }
  }

  ngAfterViewInit(): void {
    const firstInput: HTMLInputElement = this.serialInputs.nativeElement.querySelectorAll('input')[0];
    if (firstInput && this.isFirstInputFocus) {
      setTimeout(() => {
        firstInput.focus();
      }, 1000);
    }
  }

  writeValue(value: string): void {
    if (value && value.length === this.serialLength) {
      this.serial = value.split('');
    } else {
      this.serial = new Array(this.serialLength).fill('');
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onInput(index: number, event: Event): void {
    const target = event.target as HTMLInputElement;
    let value = target.value;
    value = value.replace(/\D/g, '');
    this.serial[index] = value;

    if (value && index < this.serialLength - 1) {
      const nextInput =
        this.serialInputs.nativeElement.querySelectorAll('input')[index + 1];
      if (nextInput) {
        nextInput.focus();
        this.cdr.detectChanges();
      }
    }

    for (let i = 0; i < this.serialLength; i++) {
      if (value && i < index && !this.serial[i]) {
        this.serial[i] = value;
        const currentInput = this.serialInputs.nativeElement.querySelectorAll('input')[index];
        currentInput.value = '';
        this.serial[index] = currentInput.value;
        const inputFocus = this.serialInputs.nativeElement.querySelectorAll('input')[i + 1];
        inputFocus.focus();
        this.cdr.detectChanges();
        break;
      }
    }

    this._onChange(this.serial.join(''));
  }

  onKeyDown(index: number, event: KeyboardEvent) {
    const isNumericKey = event.key >= '0' && event.key <= '9';
    const isControlKey = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Enter',
    ].includes(event.key);

    if (!isNumericKey && !isControlKey) {
      event.preventDefault();
      return;
    }

    if (isNumericKey && this.serial[index] && index <= this.serialLength - 1) {
      event.preventDefault();
      this.serial[index] = event.key;
      const nextInput =
        this.serialInputs.nativeElement.querySelectorAll('input')[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }

    switch (event.key) {
      case 'Backspace':
        if (event.target) {
          event.preventDefault();
          for (let i = index; i >= 0; i--) {
            if (this.serial[i]) {
              this.serial[i] = '';
              const previousInput =
                this.serialInputs.nativeElement.querySelectorAll('input')[
                  i === index ? i - 1 : i
                ];
              if (previousInput) {
                previousInput.focus();
              }
              break;
            }
          }
          this.serial = this.serial.filter((item) => item);
        }
        break;
      case 'ArrowLeft':
        if (index > 0) {
          const previousInput =
            this.serialInputs.nativeElement.querySelectorAll('input')[
              index - 1
            ];
          if (previousInput) {
            event.preventDefault();
            previousInput.focus();
          }
        }
        break;
      case 'ArrowRight':
        if (index < this.serialLength - 1) {
          const nextInput =
            this.serialInputs.nativeElement.querySelectorAll('input')[
              index + 1
            ];
          if (nextInput) {
            event.preventDefault();
            nextInput.focus();
          }
        }
        break;
    }
    this._onChange(this.serial.join(''));
  }

  onInputChange(value: string, index: number): void {
    this.serial[index] = value;
  }

  onFocus(index: number) {
    if (index === this.serialLength - 1) {
      this.onfocus.emit({ lastInputFocused: true });
    } else {
      this.onfocus.emit({ lastInputFocused: false });
    }
  }
}
