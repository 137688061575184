import { Component, Inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  constructor(
    private modalRef: NzModalRef,
    @Inject(NZ_MODAL_DATA) public data: any  
  ) {}

  closeModal(forceClose?: boolean) {
    if (this.data.onClick && !forceClose) {
      this.data.onClick();
      return;
    }
    this.modalRef.destroy();
  }
}
