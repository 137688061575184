import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ngSwitchCaseMultiple]'
})
export class NgSwitchCaseMultipleDirective {

  private _caseValues: any[];

  constructor(
    private viewContainer: ViewContainerRef, 
    private templateRef: TemplateRef<any>
  ) {}

  @Input()
  set ngSwitchCaseMultiple(caseValues: any[]) {
    this._caseValues = caseValues;
  }

  @Input()
  set ngSwitchCaseMultipleNgSwitch(ngSwitch: any) {
    if (this._caseValues.includes(ngSwitch)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
