<button 
  nz-button
  [type]="type" 
  [nzSize]="size"
  [class]="'flex gap-1 items-center justify-center rounded-full ' + className"
  (click)="onClick()"
  [disabled]="disabled"
  [ngClass]="{ 
    'bg-saymee-primary border-saymee-primary hover:border-saymee-primary focus:border-saymee-primary': !disabled,
    'bg-neutral/2 border-neutral/2': disabled
  }"
>
  <div *ngIf="prefix">
    <ng-container *ngTemplateOutlet="prefix"></ng-container>
  </div>
  <span *ngIf="label" [class]="'font-semibold text-[#F7F7F7] ' + lableClass">{{label}}</span>
  <div *ngIf="suffix">
    <ng-container *ngTemplateOutlet="suffix"></ng-container>
  </div>
</button>