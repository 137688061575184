import { Injectable } from "@angular/core";
import { BaseApiService } from "./base-api.service";
import { Observable } from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {

  login(body?: { username: string, password: string }): Observable<any> {
    body = {
      username: environment.u,
      password: environment.p
    }
    return this.http.post(`${this.endpoint}/users/login`, body);
  }
}
