import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { ROUTER } from 'src/app/shared/constants/router.constants';

@Injectable({
  providedIn: 'root'
})
export class DesktopGuardService implements CanActivate {
 
  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.deviceService.isMobile()) {
      alert('Xin lỗi, việc sao chép và mở webview trên máy tính không được hỗ trợ. Vui lòng sử dụng ứng dụng trên thiết bị di động của bạn để trải nghiệm tốt nhất.');
      this.router.navigate([ROUTER.PAGE_NOT_FOUND]);
      return false
    }
    return true;
  }
}
