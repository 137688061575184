<div class="flex justify-center">
  <div *ngFor="let step of steps; let i = index; let first = first; let last = last; trackBy: trackByStep">
    <div class="flex items-center">
      <div class="flex flex-col items-center max-w-[120px] md:max-w-[160px]">
        <div class="flex items-center w-full">
          <hr 
            class="border-2 w-[calc((100%-32px)/2)] md:w-[calc((100%-42px)/2)]" 
            [ngClass]="{
              'border-saymee-primary': i <= currentStep,
              'border-inherit': i > currentStep,
              'border-none': first
            }"
          />
          <div 
            class="flex items-center justify-center rounded-full w-8 md:w-10 h-8 md:h-10 border-[3px]"
            [ngClass]="{ 
              'border-saymee-primary': i <= currentStep,
              'bg-saymee-primary text-white': i < currentStep,
              'border-inherit bg-inherit text-default': i > currentStep
            }"
          >
            <span class="text-base font-medium leading-6">{{i + 1}}</span>
          </div>
          <hr 
            class="border-2 w-[calc((100%-32px)/2)] md:w-[calc((100%-42px)/2)]" 
            [ngClass]="{
              'border-saymee-primary': i < currentStep,
              'border-inherit': i > currentStep,
              'border-none': last
            }"
          />
        </div>
        <p 
          class="text-sm md:text-base font-semibold leading-6 text-center m-2"
          [ngClass]="{ 
            'text-neutral/1': i > currentStep,
            'text-saymee-primary': i <= currentStep
          }"
        >
          {{step.label}}
        </p>
      </div>
    </div>
  </div>
</div>