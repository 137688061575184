import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string, char?: string): string {
    const newValue = value?.slice(0, 3) + (char || " ") + value?.slice(3, 6) + (char || " ") + value?.slice(6)
    return newValue;
  }

}
