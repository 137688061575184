<nz-select
  class="w-full rounded-lg h-10"
  [ngModel]="value"
  [nzDisabled]="disabled"
  [nzMode]="isMulti ? 'multiple' : 'default'"
  [nzPlaceHolder]="placeholder"
  [nzLoading]="isLoading"
  [nzAllowClear]="allowClear"
  (ngModelChange)="onChange($event)"
  [nzSize]="size"
  [nzShowSearch]="showSearch">
  <nz-option
    *ngFor="let option of options"
    [nzDisabled]="option.disabled"
    [nzValue]="option.value"
    [nzLabel]="option.label">
  </nz-option>
</nz-select>
