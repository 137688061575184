import {ChangeDetectorRef, Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  setLoading(isLoading: boolean): void {
    // this.isLoading.next(isLoading);
    setTimeout(() => this.isLoading.next(isLoading));
    // this.cdr.detectChanges();
  }

  getLoading(): BehaviorSubject<boolean> {
    return this.isLoading;
  }
}
