<div #listInputs class="flex justify-center items-center gap-2">
  <div *ngFor="let item of config.length; let i = index">
    <input
      type="tel"
      [class]="config.inputClass"
      [ngStyle]="config.inputStyles"
      maxlength="1"
      (input)="onInput(i, $event)"
      (keydown)="onKeyDown(i, $event)"
      [(ngModel)]="serial[i]"
      (ngModelChange)="onInputChange($event, i)"
      (focus)="onFocus(i)">
  </div>
</div>
