import { NgModule } from '@angular/core';
import { PhoneNumberPipe } from './phone-number.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { DateMaskPipe } from './date-mask.pipe';
import { IdNumberMaskPipe } from './id-number-mask.pipe';

const pipes = [
  PhoneNumberPipe,
  SafeUrlPipe,
  DateMaskPipe,
  IdNumberMaskPipe
];

@NgModule({
  declarations: pipes,
  exports: pipes
})

export class PipesModule {}
