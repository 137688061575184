export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_FORMAT2 = 'dd - MM - yyyy';
export const DATE_FORMAT3 = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const DATE_TIME_BEFORE_FORMAT = 'HH:mm - dd/MM/yyyy';
export const DATE_TIME_AFTER_FORMAT = 'HH:mm:ss - dd/MM/yyyy';
export const DATE_TIME_TEXT_FORMAT = `HH 'giờ' mm 'phút', 'ngày' dd/MM/yyyy`;
export const DATE_TIME_TEXT_FORMAT_FULL = `HH:mm, 'Ngày' dd 'tháng' MM 'năm' yyyy`;
export const DATE_SQL_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_SQL_FORMAT = `yyyy-MM-dd'T'HH:mm:ss`;
export const DATE_TIME_FULL_FORMAT = 'dd/MM/yyyy HH:mm:ss';
export const DATE_SIGN_PLACE = `'Ngày' dd 'tháng' MM 'năm' yyyy`;
export const MONTH_FORMAT = 'MM/yyyy';
export const YEAR_FORMAT = 'yyyy';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FULL_TEXT_FORMAT = `HH 'giờ' mm 'phút', 'ngày' dd 'tháng' MM 'năm' yyyy`;
