export class NumberUtil {
	static toCurrency(value: number) {
		return value != null ? new Intl.NumberFormat('vi-VN').format(value) : '';
	}

	static toCurrencyDigit(value: number, digits: number, maxDigit?: number) {
		return value != null
      ? new Intl.NumberFormat('vi-VN', {
          minimumFractionDigits: digits,
          maximumFractionDigits: maxDigit,
        }).format(value)
      : '';
	}

  static toPhoneNumber(value: string) {
    return value?.slice(0, 4) + "." + value?.slice(4, 7) + "." + value?.slice(7);
  }
}
