import { Component, ElementRef, HostListener, Input, TemplateRef, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'input-file-upload',
  templateUrl: './input-file-upload.component.html',
  styleUrls: ['./input-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileUploadComponent),
      multi: true
    }
  ]
})
export class InputFileUploadComponent implements ControlValueAccessor {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  @Input() bgDefaultClass: string = '';
  @Input() defaultImgSrc: string = './assets/images/img-upload-front-default.webp';
  @Input() prefixIcon: TemplateRef<any> | null = null;

  file: File | null = null;
  isHovered = false;
  fileUrl: string | ArrayBuffer | null = null;

  private _onChange!: (_: any) => void;
  private _onTouched!: (_?: any) => void;

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    this.isHovered = false;
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.file = files[0];
      this.displayImage();
    }
  }

  writeValue(value: any): void {
    this.file = value;
    if (this.file) {
      this.displayImage();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onFileSelected(event: Event) {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.file = input.files[0];
      this.displayImage();
    }
  }

  displayImage() {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.fileUrl = event.target.result;
      this._onChange(this.fileUrl);
    };
    reader.readAsDataURL(this.file as Blob);
  }

  openFileSelection() {
    this.fileInput.nativeElement.click();
  }
}
