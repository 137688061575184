<div 
  class="relative border-2 border-dashed border-saymee-primary flex justify-center px-6 py-4 rounded-xl h-52" 
  (mouseenter)="isHovered = true" 
  (mouseleave)="isHovered = false" 
  (drop)="onDrop($event)"
>
  <img *ngIf="!file" [src]="defaultImgSrc" alt="Default Image">
  <img *ngIf="file" [src]="fileUrl" alt="Uploaded Image">
  <input class="hidden" type="file" (change)="onFileSelected($event)" #fileInput>

  <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
    <saymee-button 
      *ngIf="isHovered || file" 
      label="Tải lên" 
      [prefix]="prefixIcon" 
      (onclick)="openFileSelection()">
    </saymee-button>
  </div>
</div>