import {AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DATE_REGEX, EMAIL_REGEX, IDENTITY_CARD_REGEX, PHONE_REGEX} from '../constants';
import {forkJoin, from, isObservable, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { isPromise } from 'ng-zorro-antd/core/util';

export function isEmptyInputValue(value: any): boolean {
	if (typeof value === 'string') {
		return value.trim().length === 0;
	}
	return (
		value == null ||
    ((typeof value === 'string' || Array.isArray(value)) && value.length === 0)
	);
}

function hasValidLength(value: any): boolean {
	return value != null && typeof value.length === 'number';
}

export class ValidatorUtil {
	static min(min: number, message?: string): ValidatorFn {
		return messageValidator(
			minValidator(min),
			message || `Vui lòng nhập số lớn hơn hoặc bằng ${min}!`
		);
	}

	static max(max: number, message?: string): ValidatorFn {
		return messageValidator(
			maxValidator(max),
			message || `Vui lòng nhập số nhỏ hơn hoặc bằng ${max}!`
		);
	}

	static required(message?: string): ValidatorFn {
		return messageValidator(
			requiredValidator,
			message || 'Vui lòng nhập đầy đủ thông tin!'
		);
	}

	static requiredTrue(message?: string): ValidatorFn {
		return messageValidator(requiredTrueValidator, message);
	}

	static requiredQty(message?: string): ValidatorFn {
		return messageValidator(requiredQtyValidator, message);
	}

	static email(message?: string): ValidatorFn {
		return messageValidator(
			emailValidator,
			message || 'Vui lòng nhập đúng định dạng email!'
		);
	}

	static phone(message?: string): ValidatorFn {
		return messageValidator(
			phoneValidator,
			message || 'Vui lòng nhập đúng định dạng số điện thoại!'
		);
	}

	static identityCard(message?: string): ValidatorFn {
		return messageValidator(
			identityValidator,
			message || 'Vui lòng nhập đúng định dạng CMND/CCCD/HC!'
		);
	}

	static minLength(minLength: number, message?: string): ValidatorFn {
		return messageValidator(
			minLengthValidator(minLength),
			message || `Vui lòng nhập tối thiểu ${minLength} ký tự!`
		);
	}

	static insuranceCardIsExpires(isExpired: any, message?: string): ValidatorFn {
		return messageValidator(
			expiresValidator(isExpired),
			message || `Thẻ hết giá trị sử dụng!`
		);
	}

  static sameOldPassword(oldValue: string, message?: string): ValidatorFn {
    return messageValidator(
      requiredSamePasswordOldValidator(oldValue),
    message || `Không được trùng giá trị cũ!`);
  }

	//Thông báo "từ ngày > đến ngày"
	static insuranceToDateIllegal(message?: string): ValidatorFn {
		return messageValidator(insuranceToDateIllegalValidator(), message);
	}

	static isNotVietnamese(isNotVietnamese: any, message?: string): ValidatorFn {
		return messageValidator(
			isVietnameseValidator(isNotVietnamese),
			message || `Dữ liệu nhập không đúng định dạng!`
		);
	}

	static maxLength(maxLength: number, message?: string): ValidatorFn {
		return messageValidator(
			maxLengthValidator(maxLength),
			message || `Vui lòng nhập tối đa ${maxLength} ký tự!`
		);
	}

	static date(message?: string): ValidatorFn {
		return messageValidator(
			dateValidator,
			message || `Vui lòng nhập đúng định dạng ngày!`
		);
	}

	static pattern(pattern: string | RegExp, message?: string): ValidatorFn {
		return messageValidator(patternValidator(pattern), message);
	}

	static nullValidator(message?: string): ValidationErrors | null {
		return messageValidator(nullValidator, message);
	}

	static compose(validators: null): null;
	static compose(
    validators: (ValidatorFn | null | undefined)[]
  ): ValidatorFn | null;
	static compose(
		validators: (ValidatorFn | null | undefined)[] | null
	): ValidatorFn | null {
		return compose(validators);
	}

	static composeAsync(
		validators: (AsyncValidatorFn | null)[]
	): AsyncValidatorFn | null {
		return composeAsync(validators);
	}
}

export function messageValidator(
	validatorFn: ValidatorFn,
	message?: string
): ValidatorFn {
	if (!message) return validatorFn;
	return (control: AbstractControl): ValidationErrors | null => {
		const error = validatorFn(control);
		if (error) {
			error['message'] = message;
		}
		return error;
	};
}

export function minValidator(min: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
			return null; // don't validate empty values to allow optional controls
		}
		const value = parseFloat(control.value);
		return !isNaN(value) && value < min
			? {min: {min: min, actual: control.value}}
			: null;
	};
}

export function maxValidator(max: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
			return null; // don't validate empty values to allow optional controls
		}
		const value = parseFloat(control.value);
		return !isNaN(value) && value > max
			? {max: {max: max, actual: control.value}}
			: null;
	};
}

export function requiredValidator(
	control: AbstractControl
): ValidationErrors | null {
	return isEmptyInputValue(control.value) ? {required: true} : null;
}

export function requiredTrueValidator(
	control: AbstractControl
): ValidationErrors | null {
	return control.value === true ? null : {required: true};
}

export function requiredQtyValidator(
	control: AbstractControl
): ValidationErrors | null {
	return control.value > 0 ? null : {required: true};
}

export function requiredSamePasswordOldValidator(oldValue: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
		return control.value == oldValue
			? {isSamePasswordOld : true}
			: null;
	};
}

export function emailValidator(
	control: AbstractControl
): ValidationErrors | null {
	if (isEmptyInputValue(control.value)) {
		return null; // don't validate empty values to allow optional controls
	}
	return EMAIL_REGEX.test(control.value?.trim()) ? null : {email: true};
}

export function phoneValidator(
	control: AbstractControl
): ValidationErrors | null {
	if (isEmptyInputValue(control.value)) {
		return null; // don't validate empty values to allow optional controls
	}
	return PHONE_REGEX.test(control.value) ? null : {phone: true};
}

export function dateValidator(
	control: AbstractControl
): ValidationErrors | null {
	if (isEmptyInputValue(control.value)) {
		return null;
	}
	return DATE_REGEX.test(control.value) ? null : {date: true};
}

export function identityValidator(
	control: AbstractControl
): ValidationErrors | null {
	if (isEmptyInputValue(control.value)) {
		return null; // don't validate empty values to allow optional controls
	}
	return IDENTITY_CARD_REGEX.test(control.value)
		? null
		: {identityCard: true};
}

export function minLengthValidator(minLength: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		let value = control.value;
		if (typeof value === 'number') {
			value = value.toString();
		}
		if (isEmptyInputValue(value) || !hasValidLength(value)) {
			return null;
		}
		return value.length < minLength
			? {
				minlength: {
					requiredLength: minLength,
					actualLength: value.length
				}
			}
			: null;
	};
}

export function expiresValidator(isExpired: any): ValidatorFn {
	return (): ValidationErrors | null => {
		return isExpired !== true ? null : {insuranceCardIsExpires: true};
	};
}

export function insuranceToDateIllegalValidator(): ValidatorFn {
	return (): ValidationErrors | null => {
		return {insuranceToDateIllegal: true};
	};
}

export function isVietnameseValidator(isNotVietnamese: any): ValidatorFn {
	return (): ValidationErrors | null => {
		return isNotVietnamese == true ? null : {isNotVietnamese: true};
	};
}

export function maxLengthValidator(maxLength: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		let value = control.value;
		if (typeof value === 'number') {
			value = value.toString();
		}
		return hasValidLength(value) && value.length > maxLength
			? {
				maxLength: {
					requiredLength: maxLength,
					actualLength: value.length
				}
			}
			: null;
	};
}

export function patternValidator(pattern: string | RegExp): ValidatorFn {
	if (!pattern) return nullValidator;
	let regex: RegExp;
	let regexStr: string;
	if (typeof pattern === 'string') {
		regexStr = '';

		if (pattern.charAt(0) !== '^') regexStr += '^';

		regexStr += pattern;

		if (pattern.charAt(pattern.length - 1) !== '$') regexStr += '$';

		regex = new RegExp(regexStr);
	} else {
		regexStr = pattern.toString();
		regex = pattern;
	}
	return (control: AbstractControl): ValidationErrors | null => {
		if (isEmptyInputValue(control.value)) {
			return null; // don't validate empty values to allow optional controls
		}
		const value: string = control.value;
		return regex.test(value)
			? null
			: {pattern: {requiredPattern: regexStr, actualValue: value}};
	};
}

export function nullValidator(
): ValidationErrors | null {
	return null;
}

function isPresent(o: any): boolean {
	return o != null;
}

export function toObservable(r: any): Observable<any> {
	const obs = isPromise(r) ? from(r) : r;
	if (!isObservable(obs)) {
		throw new Error(`Expected validator to return Promise or Observable.`);
	}
	return obs;
}

function mergeErrors(
	arrayOfErrors: (ValidationErrors | null)[]
): ValidationErrors | null {
	let res: { [key: string]: any } = {};
	arrayOfErrors.forEach((errors: ValidationErrors | null) => {
		res = errors != null ? {...res!, ...errors} : res!;
	});

	return Object.keys(res).length === 0 ? null : res;
}

type GenericValidatorFn = (control: AbstractControl) => any;

function executeValidators<V extends GenericValidatorFn>(
	control: AbstractControl,
	validators: V[]
): ReturnType<V>[] {
	return validators.map((validator) => validator(control));
}

function compose(
	validators: (ValidatorFn | null | undefined)[] | null
): ValidatorFn | null {
	if (!validators) return null;
	const presentValidators: ValidatorFn[] = validators.filter(isPresent) as any;
	if (presentValidators.length == 0) return null;

	return function (control: AbstractControl) {
		return mergeErrors(
			executeValidators<ValidatorFn>(control, presentValidators)
		);
	};
}

function composeAsync(
	validators: (AsyncValidatorFn | null)[]
): AsyncValidatorFn | null {
	if (!validators) return null;
	const presentValidators: AsyncValidatorFn[] = validators.filter(
		isPresent
	) as any;
	if (presentValidators.length == 0) return null;

	return function (control: AbstractControl) {
		const observables = executeValidators<AsyncValidatorFn>(
			control,
			presentValidators
		).map(toObservable);
		return forkJoin(observables).pipe(map(mergeErrors));
	};
}
