import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cs-input',
  templateUrl: './cs-input.component.html',
  styleUrls: ['./cs-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CsInputComponent),
      multi: true
    }
  ]
})
export class CsInputComponent implements ControlValueAccessor {
  @ViewChild('inputRef') inputRef!: ElementRef;

  @Input() placeholder = 'Vui lòng điền';
  @Input() status: any = '';
  @Input() type: 'text' | 'number' | 'email' | 'password' | 'search' | 'tel' = 'text';
  @Input() disabled: boolean = false;
  @Input() size: 'small' | 'large' | 'default' = 'default';
  @Input() maxlength: number = 999999999;
  @Input() className: string = '';
  @Input() prefix: TemplateRef<any> | string = '';
  @Input() allowClear = false;
  @Input() inputGroupClass: string = '';
  @Input() required: boolean = false;
  @Output() onInputChange = new EventEmitter();

  value: string = '';
  isFocus: boolean = false;

  private _onChange!: (_: any) => void;
  private _onTouched!: (_: any) => void;
  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  onChange(event: any): void {
    if (event === '') {
      event = null;
      this.value = event?.trim();
    }
    if (this._onChange) {
      this._onChange(event);
    }
    this.onInputChange.emit(event)
  }

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClear() {
    this.value = '';
    this._onChange('');
    this.cdr.detectChanges();
  }

  onFocus() {
    this.isFocus = true;
  }

  onBlur() {
    setTimeout(() => {
      this.isFocus = false;
    }, 500)
  }

  onPaste(event: any) {
    const clipboardData = event.clipboardData;
    const value = clipboardData.getData('text');
    if (value) {
      this.onChange(value);
    }
  }
}
