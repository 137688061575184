import { ErrorHandler } from '@angular/core';
import { InvalidFormError } from '../utils';

export class MyErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    if (!(error instanceof InvalidFormError)) {
      console.error(error);
    }
  }
}
