import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  protected endpoint: string = environment.apiUrl;

  constructor(protected http: HttpClient) {}
}
