import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SignaturePadComponent),
    multi: true
  }]
})
export class SignaturePadComponent implements AfterViewInit, ControlValueAccessor {
  
  @ViewChild('parentDiv') parentDivEl!: ElementRef; 
  @ViewChild('canvas') canvasEl!: ElementRef;

  signaturePad!: SignaturePad;

  private _onChange!: (_: any) => void;
  private _onTouched!: (_?: any) => void;

  writeValue(value: any) {
    if (value) {
      this.signaturePad.fromDataURL(value);
    } else {
      this.signaturePad?.clear();
    }
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement, {
      minWidth: 1.5,
      maxWidth: 3.5
    });

    const parentDiv = this.parentDivEl.nativeElement;
    const canvas = this.canvasEl.nativeElement;
    const rect = parentDiv.getBoundingClientRect();

    canvas.width = rect.width - 10;
    canvas.height = rect.height - 10;

    this.parentDivEl.nativeElement.addEventListener('mouseleave', () => {
      if (!this.signaturePad.isEmpty()) {
        this.savePad();
      }
    });

    this.parentDivEl.nativeElement.addEventListener('touchmove', () => {
      if (!this.signaturePad.isEmpty()) {
        this.savePad();
      }
    });
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL('image/png');
    this._onChange(base64Data);
    this._onTouched();
  }

  clearPad() {
    this.signaturePad.clear();
    this._onChange('');
    this._onTouched();
  }

  @HostListener('window:resize', ['$event'])
  resizeCanvas() {
    const parentDiv = this.parentDivEl.nativeElement;
    const canvas = this.canvasEl.nativeElement;
    const rect = parentDiv.getBoundingClientRect();
    canvas.width = rect.width - 10;
    canvas.height = rect.height - 10;

    const data = this.signaturePad.toData();
    this.signaturePad.fromData(data);
  }
}
