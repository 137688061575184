import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './layouts/main/main.component';
import { ROUTER } from './shared/constants/router.constants';
import { DesktopGuardService } from './core/services/desktop-guard.service';
import { PageNotFoundComponent } from './layouts/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: ROUTER.VERIFY_SIM,
        pathMatch: 'full',
      },
      {
        path: ROUTER.VERIFY_SIM,
        loadChildren: () => import('./modules/verify-sim/verify-sim.module').then(m => m.VerifySimModule)
      },
      {
        path: ROUTER.VERIFY_INFO,
        loadChildren: () => import('./modules/verify-information/verify-information.module').then(m => m.VerifyInformationModule)
      },
      {
        path: ROUTER.SIGN_CONTRACT,
        loadChildren: () => import('./modules/contract-signature/contract-signature.module').then(m => m.ContractSignatureModule)
      },
      {
        path: ROUTER.PHONE_NUMBER_LOOKUP,
        loadChildren: () => import('./modules/phone-number-lookup/phone-number-lookup.module').then(m => m.PhoneNumberLookupModule),
        canActivate: [DesktopGuardService]
      },
      {
        path: ROUTER.PHONE_NUMBER_DETAIL,
        loadChildren: () => import('./modules/phone-number-detail/phone-number-detail.module').then(m => m.PhoneNumberDetailModule),
        canActivate: [DesktopGuardService]
      },
      {
        path: ROUTER.CAPTURE_IMAGE_OCR_EKYC,
        loadChildren: () => import('./modules/capture-image-ocr-ekyc/capture-image-ocr-ekyc.module').then(m => m.CaptureImageOcrEkycModule),
        canActivate: [DesktopGuardService]
      },
      {
        path: ROUTER.PREVIEW_OCR_EKYC_INFO,
        loadChildren: () => import('./modules/preview-ocr-ekyc-info/preview-ocr-ekyc-info.module').then(m => m.PreviewOcrEkycInfoModule),
        canActivate: [DesktopGuardService]
      }
    ]
  },
  {
    path: ROUTER.PAGE_NOT_FOUND,
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: ROUTER.PAGE_NOT_FOUND,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
