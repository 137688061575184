import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'saymee-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() label: string = '';
  @Input() type: 'button' | 'submit' | 'reset' | 'menu' = 'button';
  @Input() size: 'default' | 'large' | 'small' = 'default';
  @Input() className: string = '';
  @Input() lableClass: string = '';
  @Input() prefix: TemplateRef<any> | null = null;
  @Input() suffix: TemplateRef<any> | null = null;
  @Input() disabled: boolean = false;
  @Output() onclick = new EventEmitter();

  onClick() {
    this.onclick.emit();
  }
}
