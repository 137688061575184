import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idNumberMask'
})
export class IdNumberMaskPipe implements PipeTransform {

  transform(value: string, char: string= '*'): string {
    if (!value || (value.length !== 9 && value.length !== 12)) return value;

    const visibleDigitsStart = 3;
    const visibleDigitsEnd = 4;
    const start = value.slice(0, visibleDigitsStart);
    const end = value.slice(-visibleDigitsEnd);
    const maskedSection = char.repeat(value.length - visibleDigitsStart - visibleDigitsEnd);

    return `${start}${maskedSection}${end}`;
  }
}
