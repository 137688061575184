import { NgModule } from "@angular/core";
import { InputFileUploadComponent } from './input-file-upload/input-file-upload.component';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ZorroAntdModule } from "../zorro-antd.module";
import { ComponentsModule } from "../components/components.module";
import { CsInputComponent } from "./cs-input/cs-input.component";
import { InputSerialNumberComponent } from "./input-serial-number/input-serial-number.component";
import {CsDatePickerComponent} from "./cs-date-picker/cs-date-picker.component";
import {CsSelectComponent} from "./cs-select/cs-select.component";

const components = [
  InputFileUploadComponent,
  CsInputComponent,
  InputSerialNumberComponent,
  CsDatePickerComponent,
  CsSelectComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    FormsModule,
    ZorroAntdModule,
    ComponentsModule
  ]
})
export class ControlsModule {}
