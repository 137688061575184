<nz-input-group
  [nzPrefix]="prefix"
  [nzSuffix]="allowClear ? clearIcon : suffix"
  [class]="inputGroupClass"
  class="w-full"
  [nzSize]="size"
>
  <input
    #inputRef
    nz-input
    [type]="type"
    [class]="className"
    [placeholder]="placeholder"
    [maxLength]="maxlength"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    [nzStatus]="status"
    [disabled]="disabled"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (paste)="onPaste($event)"
    [required]="required"
  />
</nz-input-group>

<ng-template #clearIcon>
  <button type="button" *ngIf="value?.length && isFocus" (click)="onClear()">
    <img src="./assets/icons/icon-clear.svg" alt="clear icon" />
  </button>
</ng-template>
