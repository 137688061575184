import { NgModule } from "@angular/core";
import { StepComponent } from './step/step.component';
import { ButtonComponent } from './button/button.component';
import { ZorroAntdModule } from "../zorro-antd.module";
import { CommonModule } from "@angular/common";
import { SignaturePadComponent } from "./signature-pad/signature-pad.component";
import { ModalComponent } from "./modal/modal.component";
import { ToastrComponent } from "./toastr/toastr.component";
import {FormControlComponent} from "./form-control/form-control.component";

const components = [
  StepComponent,
  ButtonComponent,
  SignaturePadComponent,
  ModalComponent,
  ToastrComponent,
  FormControlComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    ZorroAntdModule
  ]
})
export class ComponentsModule {}
