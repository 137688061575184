import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepService {
  private stepSubject = new BehaviorSubject<number>(0);
  stepData$ = this.stepSubject.asObservable();

  constructor(
    private router: Router
  ) {
    this.initializeStep();
  }

  initializeStep() {
    const currStep = localStorage.getItem('currStep');
    if (currStep) {
      this.stepSubject.next(JSON.parse(currStep));
    }
  }

  updateStep(currStep: number) {
    this.stepSubject.next(currStep);
    localStorage.setItem('currStep', JSON.stringify(currStep));
  }
}
