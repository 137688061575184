import { NgModule } from "@angular/core";
import {CapitalizeFirstDirective} from "./capitalizeFirstLetter.directive";
import { NumberOnlyDirective} from "./only-number.directive";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { NgSwitchCaseMultipleDirective } from "./ng-switch-case-multiple.directive";


const directives = [
  NumberOnlyDirective,
  CapitalizeFirstDirective,
  NgSwitchCaseMultipleDirective
]
@NgModule({
  declarations: directives,
  exports: directives,
  imports: [
    ReactiveFormsModule,
    FormsModule
  ]
})

export class DirectivesModule {

}
