<div class="flex flex-col items-center justify-center gap-4 px-0 md:px-12 relative">
  <button class="absolute -top-4 -right-4 outline-none" (click)="closeModal(true)">
    <img src="./assets/icons/icon-close.svg" alt="" />
  </button>
  <p class="text-error/01 text-xl leading-7 font-bold">{{data.title}}</p>
  <img src="./assets/images/popup-error.webp" alt="" />
  <p class="text-black text-base leading-6 text-center">{{data.message}}</p>
  <div class="w-full">
    <saymee-button [label]="data.btnLabel" size="large" className="w-full" (onclick)="closeModal()"></saymee-button>
  </div>
</div>