import { Injectable, TemplateRef } from '@angular/core';
import {OptionModel} from "../models";
import { Subject } from 'rxjs';

export interface NotificationModel {
  type: 'success' | 'info' | 'warning' | 'error';
  title: string | TemplateRef<void> | null;
  message: string | TemplateRef<void> | null;
  duration?: number
}


const titles: OptionModel[] = [
  { value: 'success', label: 'Thành công' },
  { value: 'info', label: 'Thông tin' },
  { value: 'warning', label: 'Cảnh báo' },
  { value: 'error', label: 'Lỗi' },
  { value: 'custom', label: 'Custom' }
];

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationSubject = new Subject<NotificationModel>();

  constructor() { }

  getNotificationSubject() {
    return this.notificationSubject;
  }

  show({ type, title, message, duration }: NotificationModel) {
    this.notificationSubject.next({ type, title, message, duration });
  }
}
