import { Component, Input, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cs-date-picker',
  templateUrl: './cs-date-picker.component.html',
  styleUrls: ['./cs-date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CsDatePickerComponent),
      multi: true
    }
  ]
})
export class CsDatePickerComponent implements ControlValueAccessor {
  @Input() disabled : boolean = false;
  @Input() allowClear: boolean = false;
  @Input() mode: any = 'date';
  @Input() placeholder: string = 'Vui lòng nhập...';
  @Input() size: any = 'small';
  @Input() status: any = '';
  @Input() placement: any = 'bottomLeft';
  @Input() format: any = 'dd/MM/yyyy';



  value: any = '';
  private onChange: () => void;
  private onTouched: () => void;


  constructor() { }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
