import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {ErrorHandler, NgModule, Optional, SkipSelf} from "@angular/core";
import {API_URL} from "./constants";
import { NzConfig, NZ_CONFIG } from "ng-zorro-antd/core/config";
import { environment } from '../../environments/environment';
import {AuthInterceptor} from "./interceptors/auth.interceptors";
import {MyErrorHandler} from "./handler/error.handler";
import {SharedModule} from "../shared/shared.module";
import {NotificationService} from "./services/notification.service";
import { LoadingInterceptor } from './interceptors/loading.interceptor';
const ngZorroConfig: NzConfig = {
  notification: { nzTop: 60 }
};
@NgModule({
  imports: [
    SharedModule,
    // NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' })
  ],
  providers: [
    {provide: API_URL, useValue: environment.apiUrl},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: ErrorHandler, useClass: MyErrorHandler, deps: [NotificationService]},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
    { provide: NZ_CONFIG, useValue: ngZorroConfig }
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Import this module in the AppModule only.'
      );
    }
  }
}
