import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { OptionModel } from 'src/app/core/models';

@Component({
  selector: 'cs-select',
  templateUrl: './cs-select.component.html',
  styleUrls: ['./cs-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CsSelectComponent),
      multi: true
    }
  ]
})
export class CsSelectComponent implements OnChanges,ControlValueAccessor {
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() allowClear = false;
  @Input() size: 'small' | 'default' | 'large' = 'default';
  @Input() isMulti = false;
  @Input() options: any[] | null = [];
  @Input() showSearch = false;
  @Input() isLoading: boolean | null = false;
  @Output() csChange = new EventEmitter();
  value!: string;
  filteredOptions: OptionModel<any>[] | null = [];
  _onChange!: (_: any) => void;
  _onTouched!: (_: any) => void;

  constructor() { }


  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes != null && changes['options'] != null && !changes['options'].firstChange){
      this.options=changes['options'].currentValue;
      this.filteredOptions = this.options;
    }
  }

  onChange(event: any): void {
    if (this._onChange) {
      this._onChange(event);
      this.value = event;
    }
    let data = this.options?.find(x => x.value == event);
    this.csChange.emit(data);
  }
}
