import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { DestroyService } from './core/services/destroy.service';
import { catchError, of, takeUntil } from 'rxjs';
import {LoadingService} from "./core/services/loading.service";
import { VerifySimComponent } from './modules/verify-sim/verify-sim.component';
import {LocalStorageUtil} from "./core/utils";
import { ROUTER } from './shared/constants/router.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('verifyComp', { static: false }) verifyComp!: VerifySimComponent;

  constructor(
    private router: Router,
    @Inject(AuthService) private authService: AuthService,
    public loadingService: LoadingService,
    private readonly destroy$: DestroyService,
  ) {
  }

  ngOnInit(): void {
    const token = LocalStorageUtil.getItem('accessToken')
    if (!token) {
      this.login();
    }

    this.checkInfoOCR();
    this.checkInfoEKyc();
  }

  checkInfoOCR() {
    const infoOCR = LocalStorageUtil.getItem('infoOCR');
    if (infoOCR) {
      const dataOCR = JSON.parse(infoOCR);
      if (Date.now() > dataOCR.expiredTime) {
        localStorage.removeItem('infoOCR');
      }
    }
  }

  checkInfoEKyc() {
    const infoEKyc = LocalStorageUtil.getItem('infoEKyc');
    if (infoEKyc) {
      const dataEKYC = JSON.parse(infoEKyc);
      if (Date.now() > dataEKYC.expiredTime) {
        localStorage.removeItem('infoEKyc');
      }
    }
  }

  login() {
    this.authService.login()
    .pipe(
      catchError(err => of(err)),
      takeUntil(this.destroy$)
    )
    .subscribe(res => {
      if (res.data) {
        localStorage.setItem('accessToken', res.data.accessTokenInfo.accessToken);
      }
    });
  }

  isUpdateInfoRouter() {
    return this.router.url.includes(ROUTER.PHONE_NUMBER_LOOKUP);
  }
}
