import {Directive, ElementRef, HostListener} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[appCapitalizeFirst]'
})
export class CapitalizeFirstDirective {

  constructor(private control: NgControl,private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event:any) {
    const initalValue = this._el.nativeElement.value;
    // const transformed = value.replace(/\b\w/g, firstChar => firstChar.toUpperCase());
    // this.control?.control?.setValue(transformed, {emitEvent: false});
  }

  // @HostListener('input', ['$event']) onInputChange(event:any) {
  //   const initalValue = this._el.nativeElement.value;
  //   this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
  //   if ( initalValue !== this._el.nativeElement.value) {
  //     event.preventDefault();
  //   }
  // }
}
