import { Component, Input } from '@angular/core';

export interface IStep {
  label: string
}

@Component({
  selector: 'saymee-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent {
  @Input() steps: IStep[] = [
    { label: 'Xác nhận SIM' },
    { label: 'Xác nhận thông tin' },
    { label: 'Ký hợp đồng' },
  ];

  @Input() currentStep: number = 0;

  constructor(
  ) {}

  trackByStep(index: number, step: IStep) {
    return step.label;
  }
}
