export const USERNAME_REGEX =
  /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
export const PASSWORD_REGEX =
  /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#&()–{}:;',?/*~$^+=<>]).{6,})?$/;
export const PHONE_REGEX = /^(0\d{0,9}|\+84\d{0,10}|84\d{0,10}|(?!0|\+?84)\d{0,9})$/;
export const MOBIFONE_PHONE_REGEX =  /^(089|090|093|07[06789])[0-9]{7}$/;
export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const NUMBER_REGEX = /^[0-9]+$|^$/;
export const IDENTITY_CARD_REGEX =
  /(^[a-zA-Z0-9]{8}$)|(^[a-zA-Z0-9]{9}$)|(^[a-zA-Z0-9]{12}$)/;
export const DECIMAL_REGEX = /^\d*\.?\d*$/;

//date regex
export const DATE_REGEX =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
