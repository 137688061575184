import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTER } from 'src/app/shared/constants/router.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private router: Router) {}

  isUpdateInfoRouter() {
    return (
      this.router.url.includes(ROUTER.PHONE_NUMBER_LOOKUP) ||
      this.router.url.includes(ROUTER.PHONE_NUMBER_DETAIL) ||
      this.router.url.includes(ROUTER.CAPTURE_IMAGE_OCR_EKYC)
    );
  }

  backToPreviousPage() {
    if (this.router.url.includes(ROUTER.CAPTURE_IMAGE_OCR_EKYC)) {
      this.router.navigate([ROUTER.PHONE_NUMBER_DETAIL]);
    } else if (this.router.url.includes(ROUTER.PHONE_NUMBER_DETAIL)) {
      this.router.navigate([ROUTER.PHONE_NUMBER_LOOKUP]);
    } else {
      window.location.href = 'saymee://?action=Utility';
    }
  }
}
