import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, of, takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/core/services/destroy.service';
import { ROUTER } from 'src/app/shared/constants/router.constants';
import { StepService } from 'src/app/shared/services/step.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterContentChecked {
  currStep: number = 0;

  constructor(
    private router: Router,
    private stepService: StepService,
    private cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService
  ) {}

  ngOnInit(): void {
    this.stepService.stepData$
      .pipe(
        catchError(err => of(err)),
        takeUntil(this.destroy$)
      )
      .subscribe(step => this.currStep = step);
  }

  isUpdateInfoRouter() {
    return this.router.url.includes(ROUTER.PHONE_NUMBER_LOOKUP) 
      || this.router.url.includes(ROUTER.PHONE_NUMBER_DETAIL) 
      || this.router.url.includes(ROUTER.CAPTURE_IMAGE_OCR_EKYC)
      || this.router.url.includes(ROUTER.PREVIEW_OCR_EKYC_INFO);
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }
}
